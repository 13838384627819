import React from "react"
import { Link } from "gatsby"

import * as routes from "../../utils/routes"
import { signupsDisabled } from "../../utils/config"
import { starterPercentage } from "../../utils/constants"
import AppLink from "../AppLink"
import stripeVerifiedLogo from "../../images/stripe-verified-partner.svg"

const PricingInfo = () => {
  return (
    <div className="bg-black" id="pricing-block">
      <div className="max-w-5xl mx-auto px-6 py-16 md:py-28">
        <h2 className="font-display text-white text-2xl md:text-4xl font-bold mb-5 leading-tight">
          No monthly commitments.
          <br />
          Try it now, risk-free.
        </h2>
        <h3 className="font-display text-gray-200 text-lg md:text-xl font-bold mb-5 leading-tight">
          It’s only {starterPercentage} per transaction, plus Stripe fees.
        </h3>

        <div className="mt-10 md:mt-12">
          <div className="flex flex-col sm:flex-row sm:items-center sm:mr-6">
            <AppLink
              path="/signups/new"
              params={{ plan: "starter" }}
              className="bg-payhere text-center text-white text-xl font-semibold font-display py-3 px-4 rounded-md"
            >
              {signupsDisabled ? `Book a demo` : `Get Started`}
            </AppLink>

            <p className="self-center mt-2 sm:mt-0 sm:ml-4 text-gray-400 text-xs md:text-sm">
              No credit card needed.
            </p>
          </div>
        </div>

        <p className="mt-8 md:mt-10 text-gray-300 max-w-xl">
          If you process in high volume we also have{" "}
          <Link to={routes.pricing} className="underline">
            monthly plans available
          </Link>
          .
        </p>

        <a
          href="https://stripe.com/partners/payhere"
          target="_blank"
          rel="nofollow noreferrer"
        >
          <img
            src={stripeVerifiedLogo}
            alt="Stripe Verified Partner"
            className="mt-8 md:mt-10 mx-auto md:mx-0"
          />
        </a>
      </div>
    </div>
  )
}

export default PricingInfo
