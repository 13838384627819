import "payhere-embed-sdk/dist/react.css"
import Payhere from "payhere-embed-sdk/dist/react"
import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { signupsDisabled } from "../utils/config"
import AppLink from "../components/AppLink"
import SEO from "../components/seo"
import TrustedBy from "../components/TrustedBy"
import Layout from "../components/Layouts/Base"
import ContentSection from "../components/ContentSection"
import quotePhoto from "../images/robert.jpg"
import PricingInfo from "../components/Pricing/PricingInfo"

export const pageQuery = graphql`
  query CoursesLandingPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    heroImage: file(relativePath: { eq: "red-mockup.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 384) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bubblesImage: file(relativePath: { eq: "bubbles-mock.png" }) {
      childImageSharp {
        fluid(maxWidth: 384) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    storefrontImage: file(relativePath: { eq: "web-builder-mockup.png" }) {
      childImageSharp {
        fluid(maxWidth: 384) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    oneOffIcon: file(relativePath: { eq: "icons/one-off-payments.png" }) {
      childImageSharp {
        fluid(maxWidth: 165) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    easySubsIcon: file(relativePath: { eq: "icons/easy-subscriptions.png" }) {
      childImageSharp {
        fluid(maxWidth: 165) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    customFieldsIcon: file(relativePath: { eq: "icons/custom-fields.png" }) {
      childImageSharp {
        fluid(maxWidth: 165) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    recurringDonationsIcon: file(
      relativePath: { eq: "icons/recurring-donations.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 165) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    receiptsIcon: file(relativePath: { eq: "icons/receipts-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 165) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    notifyIcon: file(relativePath: { eq: "icons/notify.png" }) {
      childImageSharp {
        fluid(maxWidth: 165) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    metricsIcon: file(relativePath: { eq: "icons/metrics.png" }) {
      childImageSharp {
        fluid(maxWidth: 165) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sanityPage(slug: { current: { eq: "homepage" } }) {
      heading
      seoTitle
      seoDescription
      metadata {
        key
        value
      }
    }
  }
`

const CoursesPage = ({ data }) => {
  const [showPayhere, setShowPayhere] = useState(false)
  const siteTitle = data.site.siteMetadata.title
  const { seoTitle, seoDescription } = data.sanityPage
  const heroImage = data.heroImage.childImageSharp.fluid
  const bubblesImage = data.bubblesImage.childImageSharp.fluid
  const storefrontImage = data.storefrontImage.childImageSharp.fluid

  const oneOffIcon = data.oneOffIcon.childImageSharp.fluid
  const easySubsIcon = data.easySubsIcon.childImageSharp.fluid
  const customFieldsIcon = data.customFieldsIcon.childImageSharp.fluid
  const recurringDonationsIcon =
    data.recurringDonationsIcon.childImageSharp.fluid
  const receiptsIcon = data.receiptsIcon.childImageSharp.fluid
  const notifyIcon = data.notifyIcon.childImageSharp.fluid
  const metricsIcon = data.metricsIcon.childImageSharp.fluid

  return (
    <Layout>
      <SEO
        title={seoTitle || siteTitle}
        description={seoDescription}
        keywords={[
          `payments`,
          `online payments`,
          `card payments`,
          `recurring payments`,
          `subscriptions`,
          `recurring donations`,
          `donations`,
          `stripe`,
          `stripe billing`,
          `no-code stripe`,
          `no-code payments`,
          `stripe recurring payments`,
        ]}
      />

      <div className="max-w-5xl mx-auto p-6 flex flex-col md:flex-row md:items-center md:justify-between pb-16 md:pb-28">
        <div className="md:w-1/2">
          <h1 className="text-2xl font-display text-black font-bold leading-8 md:text-6xl sm:leading-tight">
            Effortlessly sell your course or educational content.
          </h1>

          <div className="my-8 flex justify-between md:justify-start">
            <AppLink
              path="/signups/new"
              params={{ plan: "starter" }}
              className="bg-payhere text-white text-lg font-semibold font-display p-3 rounded-md"
            >
              {signupsDisabled ? `Book a demo` : `Try it now`}
            </AppLink>
            <button
              type="button"
              className="bg-gray-50 text-gray-500 text-lg font-semibold font-display p-3 rounded-md md:ml-4"
              onClick={() => setShowPayhere(true)}
            >
              See it in action
            </button>
          </div>

          <div className="flex items-center">
            <img
              src={quotePhoto}
              alt="Robert – Glide certified expert"
              className="rounded-full w-16 h-16 mr-5"
            />
            <blockquote className="leading-tight">
              <span className="block text-base md:text-lg text-black font-semibold">
                <span className="-ml-2">“</span>The team is super responsive to
                feedback, I love the product”
              </span>
              <span className="block mt-1 text-xs md:text-base text-gray-500 font-semibold">
                Robert &ndash; Glide{" "}
                <span className="hidden md:inline">certified</span> expert
              </span>
            </blockquote>
          </div>
        </div>
        <div className="w-full mt-8 md:mt-0 md:ml-12 md:max-w-xs lg:max-w-sm lg:ml-16">
          <Img
            fluid={heroImage}
            className="rounded-xl"
            backgroundColor="#D4002E"
            loading="eager"
            fadeIn={false}
          />
        </div>
      </div>

      <ContentSection title="Create a link.">
        <p className="md:text-lg max-w-3xl">
          Give your product or service a name and a price, choose if it’s
          recurring, one-off or donation. That’s all you need to sell online, so
          simple anyone can do it!
        </p>
        <div className="mt-12 md:mt-16 flex flex-wrap items-start justify-between">
          <div className="w-36 sm:w-40 mb-4 sm:mb-6 md:mb-14">
            <Img
              fluid={oneOffIcon}
              className="rounded-lg bg-blue-100 h-36 sm:h-40"
              alt="One-off payments"
            />
            <span className="block text-center text-xs sm:text-base mt-2">
              One-off payments
            </span>
          </div>
          <div className="w-36 sm:w-40 mb-4 sm:mb-6 md:mb-14">
            <Img
              fluid={easySubsIcon}
              className="rounded-lg bg-blue-100 h-36 sm:h-40"
              alt="Easy subscriptions & instalment plans"
            />
            <span className="block text-center text-xs sm:text-base mt-2">
              Easy subscriptions &amp; instalment plans
            </span>
          </div>
          <div className="w-36 sm:w-40 mb-4 sm:mb-6 md:mb-14">
            <Img
              fluid={customFieldsIcon}
              className="rounded-lg bg-blue-100 h-36 sm:h-40"
              alt="Add custom fields for additional info"
            />
            <span className="block text-center text-xs sm:text-base mt-2">
              Add custom fields for additional info
            </span>
          </div>
          <div className="w-36 sm:w-40 mb-4 sm:mb-6 md:mb-14">
            <Img
              fluid={recurringDonationsIcon}
              className="rounded-lg bg-blue-100 h-36 sm:h-40"
              alt="Recurring Donations with Gift Aid"
            />
            <span className="block text-center text-xs sm:text-base mt-2">
              Recurring donations with Gift Aid
            </span>
          </div>
        </div>

        <div className="mt-6 md:mt-0">
          <Link
            to="/features/"
            className="bg-gray-50 text-gray-500 text-lg font-semibold font-display p-3 rounded-md"
          >
            See more features
          </Link>
        </div>
      </ContentSection>

      <ContentSection title="Share it anywhere.">
        <div className="md:flex md:items-center md:justify-between md:-mt-12 lg:-mt-16">
          <Img
            fluid={bubblesImage}
            alt="Payment links you can use anywhere"
            className="rounded-xl mb-4 md:mb-0 md:max-w-sm w-full md:ml-10 lg:ml-20 md:order-1"
          />
          <div className="md:order-0 md:flex-auto">
            <h3 className="text-lg md:text-xl font-bold text-black mb-2 md:mb-3">
              A link is all you need
            </h3>
            <p className="md:text-lg max-w-2xl">
              Send a link to just one person, or sell the same service multiple
              times. Get paid on social platforms, via email or even during
              video calls.
            </p>
          </div>
        </div>

        <div className="my-12 md:my-16 flex items-center gap-2 sm:gap-4">
          <div className="flex-auto h-0 border-b border-gray-200"></div>
          <div className="uppercase text-gray-400 font-semibold text-sm">
            And if you need more
          </div>
          <div className="flex-auto h-0 border-b border-gray-200"></div>
        </div>

        <div className="md:flex md:items-center md:justify-between">
          <Img
            fluid={storefrontImage}
            alt="Storefront"
            className="rounded-xl mb-4 md:mb-0 md:max-w-sm w-full md:ml-10 lg:ml-20 md:order-1"
          />
          <div className="md:order-0 md:flex-auto">
            <h3 className="text-lg md:text-xl font-bold text-black mb-2 md:mb-3">
              Your online Storefront
            </h3>
            <p className="md:text-lg max-w-2xl">
              Create a simple one-page website with all your payment links
              displayed. Use it as your website to get started, or link to it
              from your existing site or social profile.
            </p>
            <p className="md:text-lg max-w-2xl mt-3">
              <a
                href="https://sandbox.payhere.co/my-co-working-space"
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-payhere"
              >
                See an example
              </a>
            </p>
          </div>
        </div>
      </ContentSection>

      <ContentSection title="Get paid.">
        <p className="md:text-lg max-w-2xl">
          Our simple payment links make it easy for your customers to pay
          one-off or automatic recurring payments – weekly, monthly or yearly.
        </p>
        <div className="mt-12 md:mt-16 flex flex-wrap items-start justify-between md:justify-start">
          <div className="w-36 sm:w-40 sm:mr-6 md:mr-14 mb-4 sm:mb-6 md:mb-14">
            <Img
              fluid={metricsIcon}
              className="rounded-lg bg-blue-100 h-36 sm:h-40"
              alt="Track subscribers, payments and overall performance"
            />
            <span className="block text-center text-xs sm:text-base mt-2">
              Track subscribers &amp; cashflow
            </span>
          </div>
          <div className="w-36 sm:w-40 sm:mr-6 md:mr-14 mb-4 sm:mb-6 md:mb-14">
            <Img
              fluid={notifyIcon}
              className="rounded-lg bg-blue-100 h-36 sm:h-40"
              alt="Instant payment notifications"
            />
            <span className="block text-center text-xs sm:text-base mt-2">
              Instant notifications
            </span>
          </div>
          <div className="w-36 sm:w-40 mb-4 sm:mb-6 md:mb-14">
            <Img
              fluid={receiptsIcon}
              className="rounded-lg bg-blue-100 h-36 sm:h-40"
              alt="Custom branded receipts"
            />
            <span className="block text-center text-xs sm:text-base mt-2">
              Automatic branded email receipts
            </span>
          </div>
        </div>
      </ContentSection>

      <PricingInfo />

      <TrustedBy />

      <Payhere
        selector="#payhere-modal"
        open={showPayhere}
        embedURL="https://app.payhere.co/payhere/buy-us-a-coffee"
        onClose={() => setShowPayhere(false)}
      />
    </Layout>
  )
}

export default CoursesPage
